import React, { useEffect, useState } from "react";

import "./App.scss";
import InfosBar from "./components/InfosBar";
import ErrorPage from "./components/ErrorPage";
import MediaViewer from "./components/MediaViewer/index";
import ContentAndImages from "./components/ContentAndImages";
import { getPublicMessage, saveRegionUrlInfos } from "./service/api";
import { getExtensionInfos } from "./helper/fileExtension";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get("token");
  const context = urlParams.get("context");
  const redirectPath = urlParams.get("redirect_path");
  const s3Region = urlParams.get("region");

  const [pdfInfos, setPdfInfos] = useState({});
  const [error, setError] = useState(null);

  async function init() {
    console.log("init", process.env.REACT_APP_SERVER_ENV);
    const { web, api } = await saveRegionUrlInfos(
      process.env.REACT_APP_SERVER_ENV || "prod",
      s3Region || "eu-west-3"
    );

    console.log({ web, api });

    window.localStorage.setItem("webUrl", web);
    window.localStorage.setItem("apiUrl", api);

    const webUrl =
      (context === "ENEDIS"
        ? process.env.REACT_APP_ARCHIREPORT_EREPORT_WEB
        : web) || "https://web.archireport.com";

    if (redirectPath && redirectPath !== "") {
      if (context) {
        window.location = `${webUrl}/web/projects?redirect_path=${redirectPath}`;
      } else {
        let url = webUrl;

        if (window.location.href.includes("enedis")) {
          url = process.env.REACT_APP_ARCHIREPORT_EREPORT_WEB;
        }
        window.location = `${url}/web/projects?redirect_path=${redirectPath}`;
      }
    }

    if (!accessToken) {
      return;
    }

    try {
      const pdfInfos = await getPublicMessage(
        accessToken,
        window.location.search
      );

      if (pdfInfos) {
        if (pdfInfos.redirect) {
          window.location = pdfInfos.redirect;
        }

        if (pdfInfos.data) {
          if (pdfInfos.data.reports) {
            pdfInfos.data.reports = pdfInfos.data.reports.map((report) => {
              return { ...report, ...getExtensionInfos(report.fileName) };
            });
          }

          if (pdfInfos.data.attachedFiles) {
            // report with attachments
            pdfInfos.data.attachedFiles = pdfInfos.data.attachedFiles.map(
              (file) => {
                return { ...file, ...getExtensionInfos(file.fileName) };
              }
            );
          } else if (pdfInfos.data.images) {
            // reminder
            pdfInfos.data.images = pdfInfos.data.images.map((image, index) => {
              image.fileName = !image.fileName
                ? `image_${index + 1}.jpeg`
                : image.fileName;
              return { ...image, ...getExtensionInfos(image.fileName) };
            });
          } else if (pdfInfos.data.files) {
            // file
            pdfInfos.data.files = pdfInfos.data.files.map((file) => {
              return { ...file, ...getExtensionInfos(file.fileName) };
            });
          }

          if (pdfInfos.data.reports && pdfInfos.data.reports.length) {
            pdfInfos.downloadUrl = pdfInfos.data.reports[0].fileUrl;
          }
        }

        if (
          pdfInfos.recipients_to_sign &&
          pdfInfos.recipients_to_sign.length > 0
        ) {
          pdfInfos.signUrl = `${webUrl}/web/projects?redirect_path=${encodeURIComponent(
            `/web/message/inbox/${pdfInfos._id}`
          )}`;
        }
      }

      console.log("pdfInfos", pdfInfos);
      setPdfInfos(pdfInfos);
    } catch (e) {
      console.error("init error", e);
      setError(e);
    }
  }
  useEffect(() => {
    (async () => {
      await init();
    })();
    // eslint-disable-next-line
  }, []);

  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <div className="App" style={{ display: pdfInfos.type ? "flex" : "none" }}>
      {pdfInfos.type ? <InfosBar {...pdfInfos}></InfosBar> : null}

      {["file", "report", "planning"].includes(pdfInfos.type) &&
      pdfInfos.data &&
      (pdfInfos.data.files || pdfInfos.data.reports) ? (
        <MediaViewer
          file={
            pdfInfos.data.files
              ? pdfInfos.data.files[0]
              : pdfInfos.data.reports[0]
          }
        ></MediaViewer>
      ) : null}

      {["reminder", "sharing"].includes(pdfInfos.type) ? (
        <ContentAndImages {...pdfInfos}></ContentAndImages>
      ) : null}
    </div>
  );
}

export default App;
