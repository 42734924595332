import React, { useState } from "react";
import "./ContentAndImages.scss";
import PopupViewer from "./PopupViewer";

const ContentAndImages = ({ content, data }) => {
  console.log("images", data.images);

  const [showPopup, setShowPopup] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

  const hideModal = () => {
    setSelectedFileIndex(-1);
    setShowPopup(false);
  };

  const showModal = (index) => {
    setSelectedFileIndex(index);
    setShowPopup(true);
  };

  return (
    <div className="message-container">
      <div className="message-content">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        <div>
          {data.images &&
            data.images.map((image, index) => [
              <div
                className="message-img"
                key={index}
                style={{ backgroundImage: `url(${image.fileUrl})` }}
                onClick={() => showModal(index)}
              ></div>,
            ])}
        </div>
      </div>

      <PopupViewer
        onClose={hideModal}
        show={showPopup}
        selectedFileIndex={selectedFileIndex}
        files={data.images}
      ></PopupViewer>
    </div>
  );
};

export default ContentAndImages;
