import React from "react";
import i18n from "../i18n";
import "./ErrorPage.scss";
import SVGIcon from "./SVGIcon";

const ErrorPage = ({ error }) => {
  console.log("ErrorPage", error);
  let errorMessage = i18n.t("ErrorMessage_TokenError");
  let errorMessage2 = "";

  if (error.type === "NOT_FOUND") {
    errorMessage = i18n.t("ErrorMessage_NotFound");
  } else if (error.type === "UNAUTHORIZED") {
    errorMessage = i18n.t("ErrorMessage_Unauthorized");
    errorMessage2 = i18n.t("ErrorMessage_LinkExpired");
  }

  return (
    <div className="error-container">
      <div className="error-content">
        <SVGIcon
          className="alert-icon"
          src="images/alert-triangle.svg"
          alt="alert icon"
        />
        <p className="text-main">{errorMessage}</p>
        {errorMessage2 ? <p className="text-regular">{errorMessage2}</p> : ""}
      </div>
    </div>
  );
};

export default ErrorPage;
