import React from "react";

import PDFViewer from "./PDFViewer";
import ImageViewer from "./ImageViewer";
import VideoViewer from "./VideoViewer";
import UnkownViewer from "./UnkownViewer";

const MediaViewer = ({ file }) => {
  console.log("MediaViewer file", file);
  return (
    <div className="file">
      {file && file.type === "image" ? (
        <ImageViewer file={file}></ImageViewer>
      ) : null}
      {file && file.type === "pdf" ? (
        <PDFViewer downloadUrl={file.fileUrl}></PDFViewer>
      ) : null}
      {file && file.type === "video" ? (
        <VideoViewer file={file}></VideoViewer>
      ) : null}

      {file && !["pdf", "video", "image"].includes(file.type) ? (
        <UnkownViewer file={file}></UnkownViewer>
      ) : null}
    </div>
  );
};

export default MediaViewer;
