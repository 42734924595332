export const getExtensionInfos = function (filename) {
  const regexEXT = /(?:\.([^.]+))?$/;
  let extension = regexEXT.exec(filename)[1];
  let type = "random";

  if (!extension) {
    type = "random";
  } else {
    extension = extension.toLowerCase();

    if (extension === "pdf") {
      type = "pdf";
    } else if (
      extension === "xls" ||
      extension === "xlsx" ||
      extension === "csv" ||
      extension === "numbers" ||
      extension === "ods"
    ) {
      type = "xls";
    } else if (
      extension === "png" ||
      extension === "jpeg" ||
      extension === "jpg" ||
      extension === "tiff" ||
      extension === "webp" ||
      extension === "gif" ||
      extension === "heic" ||
      extension === "heif" ||
      extension === "svg"
    ) {
      type = "image";
    } else if (
      extension === "zip" ||
      extension === "rar" ||
      extension === "tar" ||
      extension === "tar.gz"
    ) {
      type = "archive";
    } else if (
      extension === "doc" ||
      extension === "docx" ||
      extension === "pages" ||
      extension === "odt"
    ) {
      type = "doc";
    } else if (
      extension === "ppt" ||
      extension === "pptx" ||
      extension === "keynote" ||
      extension === "odp"
    ) {
      type = "presentation";
    } else if (
      extension === "html" ||
      extension === "css" ||
      extension === "js" ||
      extension === "xml" ||
      extension === "json"
    ) {
      type = "code";
    } else if (extension === "txt" || extension === "rtf") {
      type = "text";
    } else if (
      extension === "mp4" ||
      extension === "m4v" ||
      extension === "mkv" ||
      extension === "mov" ||
      extension === "avi" ||
      extension === "webm"
    ) {
      type = "video";
    } else {
      type = "random";
    }
  }

  return { extension, type };
};
