import React from "react";
import AsyncImage from "../AsyncImage";

const ImageViewer = ({ file }) => {
  return file ? (
    <AsyncImage
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
      src={file.fileUrl}
      alt={file.fileName}
    />
  ) : null;
};

export default ImageViewer;
