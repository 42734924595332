import React, { useState, useEffect } from "react";
import "./PopupViewer.scss";
import MediaViewer from "./MediaViewer/index";
import i18n from "../i18n";
import SVGIcon from "./SVGIcon";

const PopupViewer = ({ show, onClose, selectedFileIndex, files }) => {
  console.log("{ show, onClose, selectedFile, files }", {
    show,
    onClose,
    selectedFileIndex,
    files,
  });

  const [fileIndex, setFileIndex] = useState(selectedFileIndex);

  useEffect(() => {
    console.log("useEffect");
    setFileIndex(selectedFileIndex);
  }, [selectedFileIndex]);

  const closePopup = (e) => {
    setFileIndex(-1);
    onClose && onClose(e);
  };

  const previousFile = () => {
    if (fileIndex >= 1) {
      setFileIndex(fileIndex - 1);
    }
  };

  const nextFile = () => {
    // console.log("fileIndex", fileIndex);
    // console.log("files.length", files.length);
    if (fileIndex < files.length - 1) {
      setFileIndex(fileIndex + 1);
    }
  };

  const clickDownload = (e, fileUrl) => {
    e.stopPropagation();
    window.open(fileUrl, "_blank");
  };

  if (fileIndex < 0 || !files || (files && files.length === 0)) return null;
  else
    return (
      <div className="popup-viewer" style={{ display: show ? "flex" : "none" }}>
        <header>
          <div className="left-part">
            <div className="extension">
              <img
                src={`images/file-${files[fileIndex].type || "random"}.svg`}
                loading="lazy"
                alt={`${files[fileIndex].type} icon`}
              />
            </div>
            <p>
              {` ${fileIndex + 1}/${files.length} • ${
                files[fileIndex].fileName
              }`}
            </p>
          </div>
          <div className="right-part">
            <button
              className="btn-transparent"
              onClick={(e) => {
                clickDownload(e, files[fileIndex].fileUrl);
              }}
            >
              <SVGIcon src="images/icon-download.svg" alt="download icon" />
              <span>{i18n.t("Landing_Download")}</span>
            </button>
            <button
              className="btn-transparent close-viewer"
              onClick={(e) => closePopup(e)}
            >
              <SVGIcon src="images/viewer-close.svg" alt="close viewer icon" />
              <span>{i18n.t("Global_Close")}</span>
            </button>
          </div>
        </header>

        <div className="content">
          {/* LEFT ARROW */}
          <div
            className={`left-arrow ${fileIndex === 0 ? "hidden" : ""}`}
            onClick={() => previousFile()}
          >
            <SVGIcon src="images/viewer-arrow-left.svg" alt="previous icon" />
          </div>

          {fileIndex >= 0 ? (
            <MediaViewer file={files[fileIndex]}></MediaViewer>
          ) : null}

          {/* RIGHT ARROW */}
          <div
            className={`right-arrow ${
              fileIndex === files.length - 1 ? "hidden" : ""
            }`}
            onClick={() => nextFile()}
          >
            <SVGIcon src="images/viewer-arrow-right.svg" alt="next icon" />
          </div>
        </div>
      </div>
    );
};

export default PopupViewer;
