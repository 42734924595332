import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import i18n from "../i18n";
import "./InfosBar.scss";
import Attachments from "./Attachments";
import { getDayjsInstance } from "../helper/locale";
import { isDarkTheme, isMobileOnly } from "../helper/theme";
import SVGIcon from "./SVGIcon";

const dayjs = getDayjsInstance();

const InfosBar = ({
  type,
  downloadUrl,
  projectImage,
  subject,
  account,
  date,
  data,
  signUrl,
}) => {
  const [showInfoBar, setShowInfoBar] = useState(!isMobileOnly());

  const displayedDateString = dayjs(date).fromNow();
  const detailedDateString = dayjs(date).format("LLL");

  let displayAccountName = "";

  if (account && (account.firstname || account.lastname || account.company)) {
    if (account.firstname && account.firstname !== "") {
      displayAccountName += account.firstname;
    }

    if (account.lastname && account.lastname !== "") {
      displayAccountName += ` ${account.lastname}`;
    }

    if (account.company && account.company !== "") {
      if (displayAccountName !== "") {
        displayAccountName += ` - ${account.company}`;
      } else {
        displayAccountName += account.company;
      }
    }
  }

  displayAccountName = displayAccountName.trim();

  return (
    <header
      className={`info-bar info-bar-${type} ${
        !showInfoBar ? "info-bar-closed" : ""
      }  `}
    >
      <ReactTooltip />
      <div
        className="btn btn-small btn-infobar-switch"
        onClick={() => {
          setShowInfoBar(!showInfoBar);
        }}
      >
        <SVGIcon
          className="switch-close"
          src="images/icon-close.svg"
          alt="close-open icon"
        />
      </div>
      <div className="main-content">
        <div className="subject">
          <div className="subject-content">
            {projectImage ? (
              <img src={projectImage} alt="account icon" />
            ) : null}
            <div className="subject-infos">
              <p className="text-main">{data.project_name || subject}</p>
              <p className="text-sec">{data.report_name}</p>
            </div>
          </div>
          <div
            className="btn btn-small btn-infobar-switch-mobile"
            onClick={() => {
              setShowInfoBar(!showInfoBar);
            }}
          >
            <SVGIcon
              className="switch-close-mobile"
              src="images/icon-close-mobile.svg"
              alt="close-open icon"
            />
            <SVGIcon
              className="switch-open-mobile"
              src="images/icon-open-mobile.svg"
              alt="close-open icon"
            />
          </div>
        </div>

        <div className="date">
          <p
            className="text-regular"
            data-tip={detailedDateString}
            data-type={isDarkTheme() ? "light" : "dark"}
          >
            {displayedDateString}
          </p>
          <div className="divider"></div>
        </div>

        <div className="account-infos">
          <div>
            {account && account.image_url ? (
              <img
                className="header-image"
                src={account.image_url}
                alt="account icon"
              />
            ) : null}
            <div>
              <p className="text-regular">{i18n.t("Message_SentBy")}</p>
              {displayAccountName !== "" ? (
                <p
                  className="text-sec"
                  data-tip={account.email}
                  data-type={isDarkTheme() ? "light" : "dark"}
                >
                  {displayAccountName}
                </p>
              ) : null}

              {displayAccountName === "" ? (
                <p className="text-sec">{account.email}</p>
              ) : null}
            </div>
          </div>

          <div className="divider"></div>
        </div>

        {["report", "planning", "file"].includes(type) ? (
          <div className="report-download">
            {signUrl ? (
              <a
                className="btn btn-large text-sec sign-report"
                href={signUrl}
                target="_blank"
                rel="noreferrer"
              >
                <SVGIcon src="images/icon-sign.svg" alt="sign icon" />
                {i18n.t("Web_Message_Sign_Report")}
              </a>
            ) : null}

            <a
              className="btn btn-large text-sec"
              href={downloadUrl}
              target="_blank"
              rel="noreferrer"
            >
              <SVGIcon src="images/icon-download.svg" alt="download icon" />
              {i18n.t("Landing_Download")}
            </a>

            {data.attachedFiles && data.attachedFiles.length ? (
              <div className="divider"></div>
            ) : null}
          </div>
        ) : null}

        {data.attachedFiles && data.attachedFiles.length ? (
          <Attachments attachedFiles={data.attachedFiles}></Attachments>
        ) : null}
      </div>
      <a
        className="archireport-footer"
        href="https://www.archireport.com"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="footer-logo-light"
          src="images/archireport-logo-fade-light.svg"
          alt="archireport logo"
        />
        <img
          className="footer-logo-dark"
          src="images/archireport-logo-fade-dark.svg"
          alt="archireport logo"
        />
      </a>
    </header>
  );
};

export default InfosBar;
