import React from "react";
import i18n from "../../i18n";

const UnkownViewer = ({ file }) => {
  const clickDownload = (e, fileUrl) => {
    e.stopPropagation();
    window.open(fileUrl, "_blank");
  };
  if (!file) {
    return null;
  }

  return (
    <div className="file-unknown">
      <div className="extension">
        <img
          src={`images/file-${file.type || "random"}.svg`}
          alt={`${file.type} icon`}
          width="50"
        />
      </div>
      <p>{i18n.t("File_ImpossibleToPreview")}</p>
      <span
        onClick={(e) => {
          clickDownload(e, file.fileUrl);
        }}
      >
        {i18n.t("File_PleaseDownloadIt")}
      </span>
    </div>
  );
};

export default UnkownViewer;
