import React from "react";
import SVG from "react-inlinesvg";

const SVGIcon = (props) => {
  return (
    <SVG
      className={props.className}
      description={props.description}
      onError={(error) => console.log(error.message)}
      onLoad={(src, hasCache) => console.log(src, hasCache)}
      preProcessor={(code) =>
        code.replace(/fill=".*?"/g, 'fill="currentColor"')
      }
      src={props.src}
      title={props.title}
      uniqueHash={props.uniqueHash}
      uniquifyIDs={true}
    />
  );
};

export default SVGIcon;
