import React from "react";
import "./Attachments.scss";
import i18n from "../i18n";
import PopupViewer from "./PopupViewer";
import { useState } from "react";
import SVGIcon from "./SVGIcon";

const Attachments = ({ attachedFiles }) => {
  console.log("attachedFiles", attachedFiles);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

  const hideModal = () => {
    setSelectedFileIndex(-1);
    setShowPopup(false);
  };

  const showModal = (index) => {
    setSelectedFileIndex(index);
    setShowPopup(true);
  };

  const clickDownload = (e, fileUrl) => {
    e.stopPropagation();
    window.open(fileUrl);
  };

  return (
    <div className="attachments">
      <p className="text-sec">{i18n.t("Web_AttachmentHelpTitle")}</p>
      <ul>
        {attachedFiles.map((file, index) => {
          return (
            <li
              className="attached-file"
              key={index}
              onClick={() => showModal(index)}
            >
              <div className="file-left">
                <div className="extension">
                  <img
                    src={`images/file-${file.type || "random"}.svg`}
                    alt={`${file.type} icon`}
                  />
                </div>

                <p className="text-sec">{file.fileName}</p>
              </div>
              <div className="file-right">
                <SVGIcon
                  className="eye-icon"
                  src="images/icon-eye.svg"
                  alt="Show file icon"
                />
                <button
                  className="btn btn-small"
                  onClick={(e) => {
                    clickDownload(e, file.fileUrl);
                  }}
                >
                  <SVGIcon src="images/icon-download.svg" alt="download icon" />
                </button>
              </div>
            </li>
          );
        })}
      </ul>

      <PopupViewer
        onClose={hideModal}
        show={showPopup}
        selectedFileIndex={selectedFileIndex}
        files={attachedFiles}
      ></PopupViewer>
    </div>
  );
};

export default Attachments;
