import React from "react";

const VideoViewer = ({ file }) => {
  return file ? (
    <video
      controls
      autoPlay={false}
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    >
      <source src={file.fileUrl} />
    </video>
  ) : null;
};

export default VideoViewer;
