import React from "react";
import "./PDFViewer.scss";
import { isMobileOnly, isTabletOnly } from "../../helper/theme";

const PDFViewer = ({ downloadUrl }) => {
  let queryparams = "pagemode=thumbs";
  if (isMobileOnly() || isTabletOnly()) {
    queryparams = "pagemode=none";
  }

  const iframeUrl = `https://d3w0ywzdneepfx.cloudfront.net/pdfjs-2.6.347-dist/web/viewer.html?file=${encodeURIComponent(
    downloadUrl
  )}#${queryparams}`;

  console.log("iframeUrl", iframeUrl);

  return <iframe className="viewer" title="pdf" src={iframeUrl}></iframe>;
};

export default PDFViewer;
