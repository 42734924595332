export const isDarkTheme = () => {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

export const isMobileOnly = () => {
  return window.innerWidth <= 1000;
};

export const isTabletOnly = () => {
  const width = window.innerWidth;
  return width > 1000 && width <= 1450;
};
