import dayjs from "dayjs";

import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/nl";
import "dayjs/locale/pt";
import "dayjs/locale/es";
import "dayjs/locale/ru";
import "dayjs/locale/it";
import "dayjs/locale/cs";
import "dayjs/locale/he";
import "dayjs/locale/ca";

import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export const fallbackLocale = "en-US";
export const localeList = [
  "fr-FR",
  "fr-CA",
  "en-GB",
  "en-US",
  "de",
  "nl-NL",
  "pt-PT",
  "es-ES",
  "ru",
  "it",
  "cs",
  "he-IL",
];

export const getBrowserLocales = (options = {}) => {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
};

export const getCurrentLanguage = () => {
  const locales = getBrowserLocales();

  return locales && locales.length
    ? getClosestLocale(locales[0])
    : fallbackLocale;
};

export const getClosestLocale = (locale) => {
  console.log("getClosestLocale", locale);
  if (!locale) return fallbackLocale;

  if (localeList.includes(locale)) return locale;

  const lang = locale.substring(0, 2);

  console.log("lang", lang);

  const closestLocale = localeList.find((loc) => {
    return loc.startsWith(lang);
  });

  console.log("closestLocale", closestLocale);
  return closestLocale || fallbackLocale;
};

export const getDayjsInstance = () => {
  const locale = getCurrentLanguage();
  const lang = locale.substring(0, 2);
  console.log("getDayjsInstance", lang);
  dayjs.locale(lang);

  return dayjs;
};
