import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentLanguage, fallbackLocale } from "./helper/locale";

import ca_ES from "./locales/ca_ES.json";
import cs from "./locales/cs.json";
import de from "./locales/de.json";
import en_GB from "./locales/en_GB.json";
import en_US from "./locales/en_US.json";
import fr_CA from "./locales/fr_CA.json";
import fr_FR from "./locales/fr_FR.json";
import he_IL from "./locales/he_IL.json";
import it from "./locales/it.json";
import nl_NL from "./locales/nl_NL.json";
import pt_PT from "./locales/pt_PT.json";

i18n.use(initReactI18next).init({
  lng: getCurrentLanguage(),
  fallbackLng: fallbackLocale,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  missingKeyHandler: function (lng, ns, key, fallbackValue) {
    console.warn("missingKeyHandler", lng, ns, key, fallbackValue);
  },
  resources: {
    "ca-ES": { translation: ca_ES },
    cs: { translation: cs },
    de: { translation: de },
    "en-GB": { translation: en_GB },
    "en-US": { translation: en_US },
    "fr-CA": { translation: fr_CA },
    "fr-FR": { translation: fr_FR },
    "he-IL": { translation: he_IL },
    it: { translation: it },
    "nl-NL": { translation: nl_NL },
    "pt-PT": { translation: pt_PT },
  },
});

export default i18n;
